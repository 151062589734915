import Vue from "vue";
import { MLInstaller, MLCreate, MLanguage } from "vue-multilanguage";

Vue.use(MLInstaller);

export default new MLCreate({
  initial: "korea",
  save: process.env.NODE_ENV === "production",
  languages: [
    new MLanguage("english").create({
      main_title: "english title"
    }),

    new MLanguage("korea").create({
      /*home*/
      footer:
        "[37183] 경상북도 상주시 경상대로 3023 (상주시보건소 뒷건물) | TEL : 054)536-0668 | FAX : 054)536-0667 | E-mail : sangjumind@daum.net<br/>Copyright ⓒ 2021 상주시정신건강복지센터. All Rights Reserved. Made by ㈜힐러스",
      footer_m:
        "[37183] 경상북도 상주시 경상대로 3023 (상주시보건소 뒷건물)<br />TEL : 054)536-0668 | FAX : 054)536-0667 | E-mail : sangjumind@daum.net<br/>Copyright ⓒ 2021 상주시정신건강복지센터. All Rights Reserved. Made by ㈜힐러스",
      loading_text: "잠시만 기다려주세요.",
      toast_finish: "한번더 누르시면 종료됩니다.",
      main_title: "시민들의 한명 한명, 마음을 어루만져주는 마음 소통 창구",
      main_button_01: "서비스 소개",
      main_button_02: "자가 건강검진",
      main_button_03: "오감 훈련 챌린지",
      main_button_04: "힐링콘텐츠",
      main_button_05: "이용후기",
      main_button_06: "후기쓰기",
      main_result_button: "자가 진단 하기",
      main_button_01_m: "자가 건강진단",
      main_button_03_m: "호흡 훈련",
      main_button_04_m: "운동",
      main_button_05_m: "내가 본 콘텐츠",
      main_button_06_m: "추천 콘텐츠",
      main_button_07_m: "이용혜택",
      main_button_09_m: "협업기관",

      side_menu_button_01: "홈",
      side_menu_button_02: "서비스 소개",
      side_menu_button_03: "자가 건강검진",
      side_menu_button_04: "오감훈련 챌린지",
      side_menu_button_05: "힐링콘텐츠",
      side_menu_button_06: "이용후기",
      side_menu_button_07: "로그아웃",

      /*info*/
      info_title_01: "정신건강 플랫폼 숨SU:M",
      info_title_02: " 을 소개합니다.",

      /*d_s*/
      d_s_title: "아래 목록에서 원하는 검사 항목을 선택하세요.",
      d_s_title_m: "원하는 검사를 선택해 주세요.",
      d_s_list_01: "그림 검사 [ 성인 우울-불안 ]",
      d_s_list_02: "설문지 검사 [ 아동⦁청소년, 성인 ]",
      d_s_list_01_m: "그림 검사<br />[ 성인 우울-불안 ]",
      d_s_list_02_m: "설문지 검사<br />[ 아동⦁청소년, 성인 ]",
      d_s_list_btn: "선택",

      d_s_info_btn: "시작하기",

      d_s_text_btn: "뒤로",

      /*result*/
      result_sub_menu_1: "오감 훈련 챌린지",
      result_sub_menu_1_info:
        "오감훈련을 통해 <br/> 내 감정을 더 깊이 이해합니다.",
      result_sub_menu_2: "힐링콘텐츠 보기",
      result_sub_menu_2_info: "다양한 콘텐츠로 힐링합니다.",

      /*counsel*/
      counsel_start_title: "처음이신가요?",
      counsel_start_yes_btn: "네, 처음 입니다.",
      counsel_start_no_btn: "아니요, 작성 중인 챌린지가 있습니다.",
      counsel_login_header: "로그인",
      counsel_login_title: "기존 챌린지 참가시 등록하신 정보를 입력해주세요.",
      counsel_login_tel_label: "휴대 전화번호",
      counsel_login_tel_rules_1: "*숫자만 가능",
      counsel_login_tel_rules_2: "*11개 숫자 모두 입력하세요.",
      counsel_login_pw_label: "비밀번호 입력",
      counsel_login_pw_rules_1: "*숫자만 입력해주세요.",
      counsel_login_pw_check_label: "비밀번호 한번 더 입력",
      counsel_pw_legit_check: "*11자리 숫자만 입력",
      counsel_birthday_check: "*6자리 모두 입력하세요.",
      counsel_error_info: "잘못 입력하신 항목이 있습니다.",

      counsel_login_pw_check_rules_1:
        "* 비밀번호가 동일하지 않습니다. 다시 입력해 주세요.",
      counsel_login_pw_rules_2: "*4자리 숫자만 입력해주세요.",
      counsel_login_find_pw: "비밀번호를 잊어버렸어요!",
      counsel_login_btn: "확인",
      counsel_login_alert_error_1:
        "이용한 이력이 없으시거나, 비밀번호가 틀립니다.<br />다시 확인해 주세요.",
      counsel_write_title: "고민 내용과 기간, 자세한 증상을 적어주세요.",
      counsel_write_input_ph:
        "고민 내용과 기간 그리고 자세한 증상을 적어주세요.\n상세하게 적으실 수록 더 정확한 상담이 가능합니다.",
      counsel_write_alert_title: "오감 훈련 챌린지",
      counsel_write_success_contents:
        "오감훈련 챌린지에 참여해주셔서 감사합니다",
      counsel_write_alert_error_1: "최소 20자 이상 입력해주세요.",
      counsel_write_type_1: "정신건강상담",
      counsel_write_type_2: "정신건강서비스",
      counsel_write_type_3: "기타",
      counsel_write_btn: "보내기",
      counsel_type_1: "하나. 시각<br />나는 어떤 것을 볼 때 기분이 좋은가요?",
      counsel_type_1_m:
        "하나. 시각<br />나는 어떤 것을 볼 때<br />기분이 좋은가요?",
      counsel_type_2: "둘. 호흡과 후각<br />당신은 어떤 향기를 좋아하나요?",
      counsel_type_3:
        "셋. 청각<br />내가 듣고 싶은 말은 무엇인가요?<br />어떤 말을 들을 때 행복한가요?",
      counsel_type_4:
        "넷. 촉각과 고통<br />지금 당신을 고통스럽게 하는 일은 무엇인가요?<br />가장 힘든 일은 무엇인가요?",
      counsel_type_5:
        "다섯. 고마운 사람<br />당신에게 가장 힘이 되는 사람은 누구인가요?<br />고마움을 담아 편지를 써보세요",
      counsel_write_input_ph_1:
        "여기를 눌러 글을 씁니다.\n예. 바닷가에 앉아 지는 노을을 볼 때 행복합니다.\n\n20자 이상 작성해주세요.",
      counsel_write_input_ph_2:
        "여기를 눌러 글을 씁니다.\n예. 갓 볶은 커피콩으로 내린 커피향기가 좋아요.\n\n20자 이상 작성해주세요.",
      counsel_write_input_ph_3:
        "여기를 눌러 글을 씁니다.\n예. 엄마가 최고예요.라는 말을 들으면 행복해요.\n\n20자 이상 작성해주세요.",
      counsel_write_input_ph_4:
        "여기를 눌러 글을 씁니다.\n예. 연년생 아이셋을 키우는 일이 너무 힘듭니다.\n\n20자 이상 작성해주세요.",
      counsel_write_input_ph_5:
        "여기를 눌러 글을 씁니다.\n예. 엄마, 엄마가 이 글을 읽을 수 없겠지만 사랑해요.\n\n20자 이상 작성해주세요.",
      counsel_write_check_label: "5가지 주제의 글을 모두 공개하겠습니다.",
      counsel_write_check_label_info:
        " *글을 공개하신 분들만 선물을 신청하실 수 있습니다",
      counsel_write_alert_continue:
        "작성중인 글이 있습니다. 이어서 쓰시겠습니까?",
      counsel_write_btn_text: "다음 주제 작성 ",
      counsel_write_btn_text_final: "완료하고 선물신청하기",
      counsel_write_confirm_info:
        "기존에 작성하시던 내용이 있습니다.<br />이어서 진행할까요?<br />('아니요'를 하시면 덮어쓰고 2단계부터 다시 작성합니다.)",
      counsel_write_confirm_yes_btn: "예",
      counsel_write_confirm_no_btn: "아니요",
      counsel_move_to_main: "메인페이지로 이동",
      counsel_intro_btn_1: "참가하기",
      counsel_intro_btn_2: "공개 게시판",
      counsel_open_list_title: "상주 시민분들의 공개글입니다.",

      /*after*/
      after_use_list_title: "숨SU:M 을 칭찬해주세요!",
      // after_use_list_new_title: "소중한 후기를 작성해주셔서 감사합니다. ",
      after_use_list_new_title: "이용 후기를 남겨주세요.",
      after_use_list_btn: "후기쓰기",
      after_use_list_cancel_btn: "취소",
      after_use_list_send_btn: "후기쓰기",
      after_use_list_back_btn: "목록으로",
      after_use_list_detail_title_1: "이름",
      after_use_list_detail_title_2: "제목",
      after_use_list_detail_title_3: "내용",
      after_use_write_btn_2: "등록",
      after_use_write_img_btn: "이미지 첨부 하기",
      after_use_write_img_complete: "이미지 첨부 완료",
      after_use_write_name_ph: "이름을 입력해 주세요.",
      after_use_write_title_ph: "제목을 입력해 주세요.",
      after_use_write_content_ph: "내용을 입력해 주세요.",
      after_use_write_alert_title: "이용후기 쓰기",
      after_use_write_m_title: "이용후기",
      after_use_write_m_title2: "후기 쓰기",
      after_use_write_alert_error_1: "*제목을 입력해 주세요.",
      after_use_write_alert_error_2: "*내용을 입력해 주세요.",
      after_use_write_alert_error_3: "*이름을 입력해 주세요.",
      after_use_write_alert_error_5: "*성별을 선택해 주세요.",
      after_use_write_alert_error_6: "*생년월일을 입력해 주세요.",
      after_use_write_alert_error_7: "*한글만 입력해 주세요.",
      after_use_write_alert_error_4: "첨부 사이즈는 20MB 이하만 가능합니다.",
      after_use_write_alert_success_title: "귀하의 사용후기가 등록되었습니다.",
      after_use_write_alert_success_info: "소중한 리뷰 감사합니다.",

      /*find*/
      find_pw_root: "비밀번호 재 설정",
      find_pw_title_1: "휴대전화 인증을 통해 본인 확인을 해주세요.",
      find_pw_title_2: "새로운 비밀번호를 입력해주세요.",
      find_pw_btn_1: "인증번호 받기",
      find_pw_btn_2: "확인",
      find_pw_alert_send_info:
        "문자 메시지로<br />인증번호를 발송하였습니다.<br /><br />휴대전화에서 확인하시고,<br />인증번호 4자리를 입력해주세요.",
      find_pw_timer_text: "초 안에 입력해주세요.",
      find_pw_check_lable: "인증번호 입력",
      find_pw_check_error_1:
        "인증번호가 맞지 않습니다.<br />다시 입력하시거나 인증번호를 재 발송하세요.",
      find_pw_change_info: "*4자리 숫자만 가능합니다.",
      find_pw_alert_change_success:
        "비밀번호가 변경되었습니다.<br />새 비밀번호로 로그인해 주세요.",
      find_pw_change_btn: "확인",

      /*contents*/
      contents_close_btn: "닫기",

      /* gift */
      apply_gift_title_info_sub_title:
        "오감훈련 챌린지를 주제에 맞게 성실히 완료하여<br />글을 공개한 상주시민께 선물을 드립니다.",
      apply_gift_title_info_sub_title_m:
        "오감훈련 챌린지를<br />끝까지 완료하신 상주 시민께서는<br />선물 신청을 하실 수 있습니다.",
      // apply_gift_title_info_and_sub_title_01: "선물은",
      // apply_gift_title_info_and_sub_title_02: "2주에 한 번",
      // apply_gift_title_info_and_sub_title_03: "일괄 발송합니다.",
      apply_gift_btn: "선물 신청",
      apply_gift_title: "선물 신청",
      apply_gift_name_label: "이름",
      apply_gift_sex_label: "성별",
      apply_gift_birthday_label: "생년월일6자리",
      apply_gift_addr_label: "[주소]",
      apply_gift_addr_info: "[ 오시는 길 ]",
      apply_gift_info_text:
        "* 선물 수령 관련 사항은 유선 또는 문자로 안내해드립니다.",
      apply_gift_info_notice: "[ 문의 : 054-536-0668 ]",
      apply_gift_check_label: "개인정보수집 이용동의",
      apply_gift_check_label_click: "[ 내용 보기 ]",
      apply_gift_check_error_1: "개인정보수집 이용동의를 체크해주세요.",
      apply_gift_sex_error_1: "성별을 선택해주세요.",
      apply_gift_sex_item_1: "남성",
      apply_gift_sex_item_2: "여성",
      apply_gift_alert_already_exist:
        "이미 신청하신 내역이 있습니다.<br />다른 콘텐츠를 이용해 보세요.",

      /* gift alert */
      apply_gift_alert_title: "주소검색",

      after_send_alert_title: "신청완료",
      after_send_alert_fail_title: "신청실패",
      after_send_alert_fail_name: "이름을 입력해주세요.",

      after_send_alert_fail_birth: "생년월일을 입력해주세요.",
      after_send_alert_fail_birth_length: "생년월일 6자리를 입력해주세요.",
      after_send_alert_fail_phone: "전화번호는 숫자만 입력해주세요.",
      after_send_alert_fail_address: "주소를 입력해주세요.",
      after_send_btn_click:
        "감사합니다.<br /><br />챌린지 참가를 완료하셨습니다. <br /> [ 선물 제공을 위해 입력하신 전화번호로 연락드리겠습니다. ]",

      order_kit_addr_alert_input_1: "우편번호",
      order_kit_addr_alert_input_2: "주소",
      order_kit_addr_alert_input_3: "상세주소",

      thanksdiary_addr_etc_rules_1: "상세주소를 입력해주세요.",
      thanksdiary_addr_rules: "*주소를 입력해주세요.",

      apply_gift_finish:
        "감사합니다.<br />챌린지 참가를 완료하셨습니다. <br /> [ 선물 제공을 위해 입력하신 전화번호로 연락드리겠습니다. ]",

      /*common*/
      go_home_btn: "홈으로",
      alert_ok_btn: "예",
      alert_cancel_btn: "아니오",
      alert_check_btn: "확인",

      /* 낮잠 ASMR */
      asmr_thumbnail_title_01: "자연의 소리",
      asmr_thumbnail_title_02: "봄이 오는 소리",
      asmr_thumbnail_title_03: "빗소리",
      asmr_thumbnail_title_04: "잔잔한 음악",
      asmr_thumbnail_title_sub: "[ 클릭 ]"
    })
  ]
});
